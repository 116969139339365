import { Component, OnInit, ViewChild, Renderer2, ElementRef, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { Account } from '@app/models/account.model';
import { User } from '@app/models/user.model';
import { AccountService } from '@app/services/account.service';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';
import { Institution } from '@app/models/institution.model';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import 'moment/locale/de';
import { detect } from 'detect-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedMsgService } from '@app/services/shared-msg.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Intercom } from '@supy-io/ngx-intercom';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { NotificationService } from '@app/services/notification.service';
import { RoutingState } from '@app/services/routing-state.service';
import { FormComponent as ChatGptFormComponent } from '@app/modules/chat-gpt/form/form.component';
import { SearchComponent } from '../search/search.component';

import { StreamChat, Channel as StreamChannel, ChannelData as StreamData, Message as StreamMessage, User as StreamUser } from 'stream-chat';
import { posthog } from 'posthog-js';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers: [ActionCableService]
})
export class NavigationComponent implements OnInit, OnDestroy {
  publicRoute = true;
  account: Account;
  currentUser: User;
  sidenavIconified = false;
  rightDrawerState = 'closed';
  modulesLoading = false;
  selectedInstitutionId: number;
  selectedInstitution: Institution;
  year: number;
  browserDetector = detect;
  private msgSubscription: Subscription;
  channel: Channel;
  notifications: number;
  chatNotifications: number;
  newVersion = false;

  @ViewChild('drawer') drawer;
  @ViewChild('rightDrawer') rightDrawer;
  @ViewChild('documentDrawer') documentDrawer;
  @ViewChild('contentPadding') contentPadding: ElementRef;
  @ViewChild('supportSubmenu') supportSubmenu: ElementRef;
  @ViewChild('inkassoSubmenu', { static: false }) inkassoSubmenu: ElementRef;

  showSupportSubmenu = false;
  showInkassoSubmenu = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  isMobile = false;
  protected destroy$ = new Subject<void>();

  chatClient: any;
  currentStreamUser: StreamUser;
  unreadStreamChannels: number;
  streamSubscription: any;

  constructor(private breakpointObserver: BreakpointObserver,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private accountService: AccountService,
              private authService: AuthService,
              private cookieService: CookieService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              public translate: TranslateService,
              private userService: UserService,
              private overlayContainer: OverlayContainer,
              private sharedMsgService: SharedMsgService,
              private cableService: ActionCableService,
              private notificationService: NotificationService,
              public intercom: Intercom,
              public routingState: RoutingState) {
  }

  ngOnInit() {
    this.routingState.loadRouting();
    this.detectBrowser();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkVersion();
        const isSmallScreen = this.breakpointObserver.isMatched(Breakpoints.Handset);
        if (isSmallScreen && this.drawer) {
          this.drawer.close();
        }
        if (this.rightDrawer) {
          this.rightDrawer.close();
        }
        this.publicRoute = Boolean(this.activatedRoute.root.firstChild.snapshot.data.publicRoute);
        if (event.url.includes('/print') || event.url.includes('/dokumente/viewer') || event.url.includes('/share')) {
          this.publicRoute = true;
        }
        if (!this.publicRoute) {
          this.initSidenav();
        }
        if (event.url.includes('/postfach') || event.url.includes('/inbox') || event.url.includes('/support')) {
          setTimeout(() => {
            if (this.contentPadding) {
              this.contentPadding.nativeElement.classList.add('no-padding-content');
            }
          });
        } else {
          setTimeout(() => {
            if (this.contentPadding) {
              this.contentPadding.nativeElement.classList.remove('no-padding-content');
            }
          });
        }
        if (!event.url.includes('/pdfvorlagen') && !event.url.includes('/emailvorlagen') && !event.url.includes('/textbausteine') && !event.url.includes('/messengervorlagen') && localStorage.getItem('insurgoTemplatesCreatorId')) {
          localStorage.removeItem('insurgoTemplatesCreatorId');
        }
      } else if (event instanceof RouteConfigLoadStart) {
        this.modulesLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.modulesLoading = false;
      }
    });
    this.year = moment().year();

    this.msgSubscription = this.sharedMsgService.getMsg().subscribe((msg: string) => {
      if (msg === 'reload_account') {
        this.loadAccount();
      } else if (msg === 'reload_notifications') {
        this.loadNotifications();
      } else if (msg === 'reload_chat_notifications') {
        this.loadChatNotifications();
      } else if (msg === 'reload_currentUser') {
        if (this.authService.token) {
          this.currentUser = new User().fromJson(JSON.parse(this.authService.currentUser));
        }
      } else if (msg.startsWith('open_document_sidenav') && !this.documentDrawer.opened) {
        if (!this.sidenavIconified) {
          this.iconifySidenav(false);
        }
        this.documentDrawer.open();
      } else if (msg.startsWith('close_document_sidenav')) {
        this.documentDrawer.close();
        localStorage.setItem('insurgoDocumentsIdHighlighted', null);
        if (this.sidenavIconified && !this.cookieService.check('insurgoSidenavIcon')) {
          this.iconifySidenav();
        }
      }
    });
    localStorage.setItem('insurgoDocumentsIdHighlighted', null);

    if (this.authService.token) {
      this.currentUser = new User().fromJson(JSON.parse(this.authService.currentUser));
      if (environment.production &&
        this.currentUser.id &&
        !this.currentUser.isClient &&
        this.currentUser.role !== 'insurgo' &&
        this.currentUser.role !== 'office' &&
        this.currentUser.email !== 'superadmin@insurgo.io') {
        this.userService.createIntercomUser().subscribe(
          (response) => {
            this.intercom.boot({
              app_id: 'uvw5roe3',
              type: response['type'],
              id: response['id'],
              user_id: response['user_id'],
              email: response['email'],
              name: response['name'],
              phone: response['phone'],
              created_at: response['created_at'],
              custom_launcher_selector: '.trigger-intercom',
              horizontal_padding: 25,
              widget: {
                activator: '#intercom'
              }
            });
            this.authService.upvoty(response['user_id'], response['name'], response['email']);
          }
        );
      }
      if (this.canReadSupport() && this.currentUser.stream_user_token) {
        this.initStreamUser();
      }
      if (environment.posthog.enabled && this.currentUser.id && this.currentUser.role !== 'insurgo' && this.currentUser.role !== 'office') {
        posthog.identify(this.currentUser.uid);
        posthog.group('subdomain', this.currentUser.subdomain);
      }
    }
    this.isHandset$.pipe(takeUntil(this.destroy$)).subscribe(value => this.isMobile = value);
  }

  async initStreamUser() {
    if (!this.canReadSupport() || !this.currentUser.stream_user_token) {
      return;
    }
    this.chatClient = new StreamChat(environment.stream.api_key);
    this.streamSubscription = this.chatClient.on(event => {
      if (event.unread_channels || event.unread_channels === 0) {
        this.unreadStreamChannels = event.unread_channels;
      }
      if (event.type === 'notification.added_to_channel') {
        if (this.unreadStreamChannels) {
          this.unreadStreamChannels += 1;
        } else {
          this.unreadStreamChannels = 1;
        }
      }
    });

    this.currentStreamUser = await this.chatClient.setUser(
      {
        id: this.currentUser.uid,
        first_name: this.currentUser.first_name,
        last_name: this.currentUser.last_name
      },
      this.currentUser.stream_user_token
    );
    if (this.currentStreamUser['me']) {
      this.unreadStreamChannels = this.currentStreamUser['me']['unread_channels'];
    }
  }

  canReadSupport() {
    return this.currentUser?.with_app && (this.currentUser?.isManager || this.currentUser?.isClient || this.currentUser?.can_use_chat);
  }

  showIntercomLink() {
    const user = new User().fromJson(JSON.parse(this.authService.currentUser));
    return environment.production &&
            user.id &&
            !user.isClient &&
            user.role !== 'insurgo' &&
            user.role !== 'office' &&
            user.email !== 'superadmin@insurgo.io';
  }

  setEntryBackPoint(entryPoint: string) {
    localStorage.setItem('currentEntryBack', entryPoint);
  }

  deleteEntryBackPoint() {
    if (localStorage.getItem('currentEntryBack')) {
      localStorage.removeItem('currentEntryBack');
    }
  }

  checkVersion() {
    if (environment.version === 'development') {
      return;
    }
    this.authService.checkVersion().subscribe(
      (response) => {
        if (response.trim() !== environment.version) {
          this.newVersion = true;
        }
      }
    );
  }

  loadNewVersion() {
    window.location.reload();
  }

  detectBrowser() {
    const browser = this.browserDetector();
    if (browser) {
      if (browser.name === 'ie' && parseInt(browser.version, 0) <= 11) {
        this.translate.stream('browser_outdated').subscribe((res: string) => {
          this.snackBar.open(res,
          'x', {
            duration: 360000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'style-error'
          });
        });
      }
    }
  }

  loadNotifications() {
    this.notifications = null;
    this.notificationService.count({ state: 'enabled' }).subscribe(
      (response) => {
        this.notifications = response.count;
      }
    );
  }

  loadChatNotifications() {
    this.chatNotifications = null;
    this.notificationService.count({ state: 'enabled', with_stream: '1' }).subscribe(
      (response) => {
        this.chatNotifications = response.count;
      }
    );
  }

  initSidenav() {
    if (this.authService.token) {
      this.currentUser = new User().fromJson(JSON.parse(this.authService.currentUser));

      if (this.currentUser.role !== 'insurgo' && this.currentUser.role !== 'office') {
        this.loadAccount();
      }

      if (!this.channel && this.currentUser.id && !this.currentUser.isClient && this.currentUser.role !== 'insurgo' && this.currentUser.role !== 'office') {
        this.channel = this.cableService.cable(this.currentUser.actionCable(this.authService.token)).channel('NotificationsChannel');
        if (this.channel) {
          this.channel.received().subscribe(message => {
            this.notifications = Number(this.notifications) + 1;
            if (message.stream_cid) {
              this.chatNotifications = Number(this.chatNotifications) + 1;
            }
            this.sharedMsgService.sendMsg(message.notificable_type === 'Note' ? 'reload_notes' : 'reload_messages');
          });
        }
      }

      if (this.currentUser.id && this.currentUser.role !== 'insurgo' && this.currentUser.role !== 'office') {
        this.loadNotifications();
        this.loadChatNotifications();
      }

      if (this.currentUser.isClient && (this.currentUser.institutions.length > 0 || this.currentUser.from_contacts)) {
        if (!localStorage.getItem('currentInstitutionId')) {
          if (this.currentUser.from_contacts) {
            localStorage.setItem('currentInstitutionId', '0');
            this.selectedInstitutionId = 0;
            this.selectedInstitution = new Institution();
            this.selectedInstitution.read_contracts = true;
            this.selectedInstitution.read_risks = true;
            this.selectedInstitution.read_documents = true;
            this.selectedInstitution.read_damages = true;
            this.selectedInstitution.read_messages = true;
            this.selectedInstitution.title = this.translate.instant('navigation.noInstitutionSelect');
          } else {
            localStorage.setItem('currentInstitutionId', this.currentUser.institutions[0].id.toString());
            this.selectedInstitutionId = this.currentUser.institutions[0].id;
            this.selectedInstitution = this.currentUser.institutions[0];
          }
        } else if (localStorage.getItem('currentInstitutionId') === '0' || this.currentUser.institutions.length === 0) {
          if (this.currentUser.from_contacts) {
            this.selectedInstitutionId = 0;
            this.selectedInstitution = new Institution();
            this.selectedInstitution.read_contracts = true;
            this.selectedInstitution.read_risks = true;
            this.selectedInstitution.read_documents = true;
            this.selectedInstitution.read_damages = true;
            this.selectedInstitution.read_messages = true;
            this.selectedInstitution.title = this.translate.instant('navigation.noInstitutionSelect');
            localStorage.setItem('currentInstitutionId', '0');
          } else {
            localStorage.setItem('currentInstitutionId', this.currentUser.institutions[0].id.toString());
            this.selectedInstitutionId = this.currentUser.institutions[0].id;
            this.selectedInstitution = this.currentUser.institutions[0];
          }
        } else {
          if (this.currentUser.from_contacts) {
            this.selectedInstitutionId = 0;
            this.selectedInstitution = new Institution();
            this.selectedInstitution.read_contracts = true;
            this.selectedInstitution.read_risks = true;
            this.selectedInstitution.read_documents = true;
            this.selectedInstitution.read_damages = true;
            this.selectedInstitution.read_messages = true;
            this.selectedInstitution.title = this.translate.instant('navigation.noInstitutionSelect');
          } else {
            this.selectedInstitutionId = Number(localStorage.getItem('currentInstitutionId'));
            const institution = this.currentUser.institutions.find(i => i.id === this.selectedInstitutionId);
            if (institution) {
              this.selectedInstitution = institution;
            } else {
              localStorage.setItem('currentInstitutionId', this.currentUser.institutions[0].id.toString());
              this.selectedInstitutionId = this.currentUser.institutions[0].id;
              this.selectedInstitution = this.currentUser.institutions[0];
            }
          }
        }
      }
    }

    if (this.cookieService.check('insurgoSidenavIcon')) {
      this.sidenavIconified = true;
      window.dispatchEvent(new Event('resize'));
    }
  }

  loadAccount() {
    this.accountService.getCached(1).subscribe(
      response => {
        this.account = new Account().fromJson(response);
      }
    );
  }

  selectInstitution(id: number) {
    localStorage.setItem('currentInstitutionId', id.toString());
    window.location.reload();
  }

  showInboxNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    return !this.currentUser.isClient &&
             (this.currentUser.with_vema ||
             this.currentUser.with_pdfbox ||
             this.currentUser.with_bipro ||
             this.currentUser.with_flixcheck ||
             this.currentUser.with_dropscan);
  }

  showPlusInboxNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    return !this.currentUser.isClient &&
             this.currentUser.with_inbox_plus &&
             (this.currentUser.isManager || this.currentUser.can_plus_inbox);
  }

  showStreamNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    return this.currentUser.with_stream && (this.currentUser.isManager || this.currentUser.isEmployee || this.currentUser.isExternal);
  }

  showContractsNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    if (this.currentUser.role === 'employee' || this.currentUser.role === 'manager' || this.currentUser.role === 'external') {
      return true;
    } else if (this.currentUser.isClient) {
      if (this.currentUser.institutions.length > 0 || this.currentUser.from_contacts) {
        return this.selectedInstitution && this.selectedInstitution.read_contracts;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showDocumentsNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    if (this.currentUser.role === 'employee' || this.currentUser.role === 'manager' || this.currentUser.role === 'external') {
      return true;
    } else if (this.currentUser.isClient) {
      if (this.currentUser.institutions.length > 0 || this.currentUser.from_contacts) {
        return this.selectedInstitution && this.selectedInstitution.read_documents;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showRisksNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    if (!this.currentUser.with_risks_forms) {
      return false;
    }
    if (this.currentUser.role === 'employee' || this.currentUser.role === 'manager' || this.currentUser.role === 'external') {
      return true;
    } else if (this.currentUser.isClient) {
      if (this.currentUser.institutions.length > 0 || this.currentUser.from_contacts) {
        return this.selectedInstitution && this.selectedInstitution.read_risks;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  showDamagesNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    if (this.currentUser.role === 'employee' || this.currentUser.role === 'manager' || this.currentUser.role === 'external') {
      return true;
    } else if (this.currentUser.isClient) {
      if (this.currentUser.institutions.length > 0 || this.currentUser.from_contacts) {
        return this.selectedInstitution && this.selectedInstitution.read_damages;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showKundenportalNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    if (this.currentUser.isClient) {
      if (this.currentUser.institutions.length > 0) {
        return this.selectedInstitution && this.selectedInstitution.membered_type === 'admin';
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showBoardsNavigation() {
    if (!this.currentUser?.id) {
      return false;
    }
    return this.currentUser?.isManager || this.currentUser?.isEmployee;
  }

  iconifySidenav(setCookie: boolean = true) {
    this.sidenavIconified = !this.sidenavIconified;
    window.dispatchEvent(new Event('resize'));
    if (this.sidenavIconified && setCookie) {
      this.cookieService.set('insurgoSidenavIcon', '1', 365, '/', null, environment.frontend.protocol === 'https');
    } else {
      this.cookieService.delete('insurgoSidenavIcon', '/');
    }
  }

  onDeactivate() {
    document.body.scrollTop = 0;
  }

  logOut(): void {
    this.router.navigate(['/auth/abmelden']);
  }

  ngOnDestroy() {
    if (this.msgSubscription) {
      this.msgSubscription.unsubscribe();
    }
    if (this.streamSubscription) {
      this.streamSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleThemeDark() {
    const changedUser = new User();
    changedUser.id = this.currentUser.id;
    changedUser.theme_dark = !this.currentUser.theme_dark;
    delete changedUser.institutions;
    this.userService.save(changedUser).subscribe(
        res => {
          this.currentUser = new User().fromJson(res);
          this.authService.currentUser = JSON.stringify(Object.assign({ id: this.currentUser.id }, this.currentUser));
          const oldThemeName = document.getElementById('mainBody').className;
          this.overlayContainer.getContainerElement().classList.remove(oldThemeName);
          if (this.currentUser.theme_dark) {
            document.getElementById('mainBody').className = oldThemeName.replace('-theme', '-dark-theme');
          } else {
            document.getElementById('mainBody').className = oldThemeName.replace('-dark-theme', '-theme');
          }
          this.overlayContainer.getContainerElement().classList.add(document.getElementById('mainBody').className);
        }
    );
  }

  closedRightDrawer() {
    this.rightDrawerState = 'closed';
    this.loadNotifications();
  }

  toggleShowSupportSubmenu() {
    this.showSupportSubmenu = !this.showSupportSubmenu;
    setTimeout(() =>
      this.supportSubmenu.nativeElement.scrollIntoView({ behavior: 'smooth' }
    ));
  }

  toggleShowInkassoSubmenu() {
    this.showInkassoSubmenu = !this.showInkassoSubmenu;
  }

  openAssist() {
    const position = {};
    if (!this.isMobile) {
      position['bottom'] = '50px';
      position['right'] = '50px';
    }
    this.dialog.open(ChatGptFormComponent, {
      disableClose: true,
      autoFocus: false,
      width: '600px',
      panelClass: 'mat-dialog-mobile-fullscreen',
      position
    }).afterClosed().subscribe(result => {
    });
  }

  openSearch(event: any) {
    event.stopPropagation();
    event.preventDefault();
    if (!this.dialog.openDialogs || this.dialog.openDialogs.length !== 0) return;

    const position = {};
    if (!this.isMobile) {
      position['top'] = '200px';
    }
    this.dialog.open(SearchComponent, {
      disableClose: false,
      autoFocus: true,
      width: '700px',
      panelClass: 'mat-dialog-globe-search',
      position
    }).afterClosed().subscribe(result => {
    });
  }

}
