import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, first, tap, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/services/auth.service';
import { UserService } from '@app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthActionTypes,
  LogIn, LogInSuccess, LogInFailure,
  SignUp, SignUpSuccess, SignUpFailure,
  LogOut
} from '../actions/auth.actions';
import { environment } from '@env/environment';
import { User } from '@app/models/user.model';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Intercom } from '@supy-io/ngx-intercom';
import { posthog } from 'posthog-js';

@Injectable()
export class AuthEffects {

  constructor(
    private actions: Actions,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private overlayContainer: OverlayContainer,
    public intercom: Intercom,
    private userService: UserService
  ) {}


  LogIn: Observable<any> = createEffect(() => this.actions.pipe(
      ofType(AuthActionTypes.LOGIN),
      map((action: LogIn) => action.payload),
      switchMap(payload => {
        return this.authService.logIn(payload.email, payload.password, payload.otp_attempt, payload.lang).pipe(
          map((user) => {
            return new LogInSuccess(new User().fromJson(user));
          }),
          catchError((error) => {
            return of(new LogInFailure({ error }));
          })
        );
      })
    ));



  LogInSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((user: any) => {
      this.authService.setSession(user.payload.authentication_token, user.payload.email, user.payload.subdomain);
      this.authService.currentUser = JSON.stringify(user.payload);
      if (user.payload.theme_dark) {
        const oldThemeName = document.getElementById('mainBody').className;
        this.overlayContainer.getContainerElement().classList.remove(oldThemeName);
        document.getElementById('mainBody').className = oldThemeName.replace('-theme', '-dark-theme');
        this.overlayContainer.getContainerElement().classList.add(oldThemeName.replace('-theme', '-dark-theme'));
      }
      const userLang = user.payload.lang;
      this.translate.use(userLang.match(/de|en/) ? userLang : 'de');
      if (environment.production &&
        user.payload.role !== 'client' &&
        user.payload.role !== 'insurgo' &&
        user.payload.role !== 'office' &&
        user.payload.email !== 'superadmin@insurgo.io') {
        this.userService.createIntercomUser().subscribe(
          (response) => {
            this.intercom.boot({
              app_id: 'uvw5roe3',
              type: response['type'],
              id: response['id'],
              user_id: response['user_id'],
              email: response['email'],
              name: response['name'],
              phone: response['phone'],
              created_at: response['created_at'],
              custom_launcher_selector: '.trigger-intercom',
              horizontal_padding: 25,
              widget: {
                activator: '#intercom'
              }
            });
            this.authService.upvoty(response['user_id'], response['name'], response['email']);
          }
        );
      }
      if (environment.posthog.enabled && user.payload.role !== 'insurgo' && user.payload.role !== 'office') {
        posthog.identify(user.payload.uid);
        posthog.group('subdomain', user.payload.subdomain);
      }
      if (user.payload.role === 'insurgo') {
        this.router.navigateByUrl('/backend/dashboard');
      } else if (user.payload.role === 'office') {
        this.router.navigateByUrl('/backoffice/kunden');
      } else {
        if (localStorage.getItem('redirectTo')) {
          const redirectTo = JSON.parse(localStorage.getItem('redirectTo'));
          localStorage.removeItem('redirectTo');
          this.router.navigate([redirectTo['url']], { queryParams: redirectTo['query'] });
        } else {
          this.router.navigateByUrl('/');
        }
      }
    })
  ), { dispatch: false });


  LogInFailure: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE)
  ), { dispatch: false });


  SignUp: Observable<any> = createEffect(() => this.actions.pipe(
      ofType(AuthActionTypes.SIGNUP),
      map((action: SignUp) => action.payload),
      switchMap(payload => {
        return this.authService.signUp(payload).pipe(
          map((user) => {
            return new SignUpSuccess(new User().fromJson(user));
          }),
          catchError((error) => {
            return of(new SignUpFailure({ error }));
          })
        );
      })
    ));


  SignUpSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(AuthActionTypes.SIGNUP_SUCCESS),
    tap((user: any) => {
      const url = [
        `${environment.frontend.protocol}://`,
        `${user.payload.subdomain}.${environment.frontend.base}`,
        `/auth/anmelden?token=${user.payload.authentication_token}`,
        `&email=${user.payload.email}`,
        `&tenant=${user.payload.subdomain}`,
       ].join('');
      window.location.href = url;
    })
  ), { dispatch: false });


  SignUpFailure: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(AuthActionTypes.SIGNUP_FAILURE)
  ), { dispatch: false });


  public LogOut: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(AuthActionTypes.LOGOUT),
    switchMap(payload => {
      return this.authService.signOut().pipe(
        map((response) => {
          if (environment.production) {
            this.intercom.shutdown();
          }
          if (environment.posthog.enabled) {
            posthog.reset();
          }
          this.authService.clearSession();
        }),
        catchError((error) => {
          this.authService.clearSession();
          return of(error);
        })
      );
    })
  ), { dispatch: false });


  GetStatus: Observable<any> = createEffect(() => this.actions.pipe(
      ofType(AuthActionTypes.GET_STATUS),
      switchMap(payload => {
        return this.authService.getStatus();
      })
    ), { dispatch: false });

}
