import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { User } from './models/user.model';
import moment from 'moment';
import 'moment/locale/de';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DateAdapter } from '@angular/material/core';
import { posthog } from 'posthog-js';
import { Analytics } from '@app/helpers/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(private translate: TranslateService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private overlayContainer: OverlayContainer,
              private dateAdapter: DateAdapter<Date>) {
  }

  ngOnInit() {
    this.initTenant();
    this.initTranslationLang();
  }

  initTenant() {
    if (window.location.hostname !== environment.frontend.base) {
      let tenant = '';
      if (environment.websiteTenants[window.location.hostname]) {
        tenant = environment.websiteTenants[window.location.hostname];
      } else {
        tenant = window.location.hostname.split('.')[0];
      }
      this.authService.checkTenant(tenant).subscribe(
        response => {
          this.authService.tenant = tenant;
          if (!this.authService.token) {
            this.updateTheme(response['theme'], false);
          }
          this.initTracking(tenant);
        },
        error => {
          this.router.navigate(['/404']);
        }
      );
    } else {
      this.authService.tenant = '';
      this.updateTheme('insurgo', false);
    }
  }

  updateTheme(theme: string, themeDark: boolean) {
    const className = themeDark ? `${theme}-dark-theme` : `${theme}-theme`;
    document.getElementById('mainBody').className = className;
    this.overlayContainer.getContainerElement().classList.add(className);
  }

  initTranslationLang() {
    this.translate.addLangs(['de', 'en']);
    this.translate.setDefaultLang('de');
    moment.locale('de');

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.lang) {
        this.translate.use(params.lang.match(/de|en/) ? params.lang : 'de');
        this.dateAdapter.setLocale(params.lang.match(/de|en/) ? params.lang : 'de');
        moment.locale(this.translate.currentLang);
      } else {
        if (this.authService.token) {
          this.authService.validateToken().subscribe(
            (response) => {
              this.authService.currentUser = JSON.stringify(new User().fromJson(response));
              this.updateTheme(response.theme, response.theme_dark);
              const userLang = response.lang;
              this.translate.use(userLang.match(/de|en/) ? userLang : 'de');
              this.dateAdapter.setLocale(userLang.match(/de|en/) ? userLang : 'de');
              moment.locale(this.translate.currentLang);
            },
            (error) => {
              this.translate.use('de');
              moment.locale(this.translate.currentLang);
              this.dateAdapter.setLocale('de');
              if (error.status === 401) {
                this.authService.clearSession();
                this.router.navigate(['/auth/anmelden']);
              }
            }
          );
        } else {
          const browserLang = this.translate.getBrowserLang();
          this.translate.use(browserLang.match(/de|en/) ? browserLang : 'de');
          this.dateAdapter.setLocale(browserLang.match(/de|en/) ? browserLang : 'de');
          moment.locale(this.translate.currentLang);
        }
      }
    });
  }

  initTracking(tenant: string) {
    Analytics.init(tenant);
    if (!environment.posthog.enabled) {
      return;
    }
    posthog.init(environment.posthog.api_key, { api_host: 'https://eu.posthog.com' });
    posthog.group('subdomain', tenant);
    if (tenant === 'admin') {
      posthog.opt_out_capturing();
    }
  }
}
