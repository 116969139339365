import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SearchedItem } from '../models/searched-item.model';
import pick from 'lodash-es/pick';
import { ParamsService } from './params.service';

@Injectable({
  providedIn: 'root'
})
export class SearchedItemService {
  readonly permitted_attributes = [
    'entry_title',
    'frontend_link',
    'icon'
  ];

  constructor(private http: HttpClient,
              private paramsService: ParamsService) { }


  index(): any {
    return this.http.get<SearchedItem[]>('/searched_items', { observe: 'response' });
  }

  save(record: SearchedItem): any {
    const searched_item = new SearchedItem().fromJson(pick(record, this.permitted_attributes));
    return this.http.post('/searched_items', { searched_item });
  }

}
