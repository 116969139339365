import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, take, switchMap} from 'rxjs/operators';
import get from 'lodash-es/get';

import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService;
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    const token: string = this.authService.token;
    const email: string = this.authService.email;
    const subdomain: string = this.authService.tenant;

    let url = '';
    if (request.url.includes('http:') || request.url.includes('https:') || request.url.includes('assets')) {
      url = request.url;
    } else if (subdomain) {
      url = `${environment.backend.protocol}://${subdomain}.${environment.backend.base}${request.url}`;
    } else {
      url = `${environment.backend.protocol}://${environment.backend.base}${request.url}`;
    }

    const headers = {
      'X-USER-EMAIL': email,
      'X-USER-TOKEN': token
    };
    if (request.method !== 'POST' && request.url !== '/documents') {
      headers['Content-Type'] = 'application/json';
    }
    if (request.method === 'PUT' && (request.url.includes('accounts') || request.url.includes('import/operations')) && request.body instanceof FormData && headers['Content-Type']) {
      delete headers['Content-Type'];
    }
    request = request.clone({
      setHeaders: headers,
      url
    });
    return next.handle(request);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private dialogRef: MatDialog;

  constructor(private injector: Injector, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    this.dialogRef = this.injector.get(MatDialog);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const whiteList = [
          '/auth/validate_token',
          '/auth/invitation',
          '/auth/confirmation',
          '/auth/unlock',
          '/auth/password',
          '/auth/sign_out',
          '/auth/sign_in',
          '/accounts/',
          '/clients/'
        ];
        if (error && error.status === 401 && !whiteList.some(el => request.url.includes(el))) {
          this.dialogRef.closeAll();
          this.router.navigate(['/auth/abmelden']);
        }
        return throwError(error);
      })
    );
  }
}
