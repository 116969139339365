import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');
import { StoreModule } from '@ngrx/store';
import { reducers } from './modules/authorization/store/app.states';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './modules/authorization/store/effects/auth.effects';

// our components
import { AuthGuard } from './guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor, ErrorInterceptor } from './interceptors/auth.interceptor';
import { HelpersModule } from './modules/helpers/helpers.module';
import { NavigationModule } from './modules/navigation/navigation.module';

// our modules
import { SharedModule } from '@app/modules/shared/shared.module';
import { environment } from '@env/environment';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './helpers/paginator-i18n';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?cacheBuster=${environment.version}`);
}

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HelpersModule,
        NavigationModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([AuthEffects]),
        SharedModule], providers: [
        CookieService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
