import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { Notification } from '@app/models/notification.model';
import { NotificationService } from '@app/services/notification.service';
import { forkJoin } from 'rxjs';
import { Downloader } from '@app/models/downloader.model';
import { ExportOperation } from '@app/models/export-operation.model';
import { Operation as ImportOperation } from '@app/models/import/operation.model';
import { PublicItem } from '@app/models/public-item.model';
import { ExportOperationService } from '@app/services/export-operation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contract } from '@app/models/contract.model';
import { Damage } from '@app/models/damage.model';

@Component({
  selector: 'app-notifications-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  records: Notification[] = [];
  public filter = {
    attribute: 'created_at',
    direction: 'desc',
    state: 'all'
  };
  loading = true;

  constructor(public translate: TranslateService,
              private router: Router,
              private renderer: Renderer2,
              private element: ElementRef,
              private snackBar: MatSnackBar,
              private service: NotificationService,
              private exportOperationService: ExportOperationService) {
  }

  ngOnInit() {
    this.loadRecords();
  }

  loadRecords() {
    this.loading = true;
    this.records = [];
    const params = Object.assign({}, this.filter);
    this.service.index(params).subscribe(
      (response) => {
        this.records = response.body.map(u => new Notification().fromJson(u));
        this.loading = false;
        const actionsToSave = this.records.filter(e => e.state === 'enabled').map(e => {
          e.state = 'disabled';
          return this.service.save(e);
        });

        if (actionsToSave.length > 0) {
          forkJoin(actionsToSave).subscribe();
        }
      }
    );
  }

  navigateTo(notification: Notification) {
    if (notification.notificable_type === 'Topic') {
      this.router.navigate([`/aufgaben/${notification.notificable.id}`]);
    } else if (notification.notificable_type === 'Note') {
      let noteable_type = notification.notificable['noteable_type'].toLowerCase();
      if (noteable_type === 'insurancecompanyitem') {
        noteable_type = 'insurance_company_item';
      }
      this.router.navigate([`/notizen/${notification.notificable['noteable_id']}/${noteable_type}`]);
    } else if (notification.notificable_type === 'Message') {
      // const messageable_type = notification.notificable['messageable_type'].toLowerCase();
      // this.router.navigate([`/konversationen/${notification.notificable['messageable_id']}/${messageable_type}`]);
      return;
    } else if (notification.notificable_type === 'User') {
      this.router.navigate([`/support`]);
    } else if (notification.notificable_type === 'Contract') {
      const contract = notification.notificable as Contract;
      if(contract)
      {
        this.router.navigate([`/vertraege/${contract.client_id}/${contract.id}`]);
      }
    } else if (notification.notificable_type === 'Damage') {
      const damage = notification.notificable as Damage;
      if(damage)
      {
        this.router.navigate([`/schaden/${damage.contract_id}/${damage.id}`]);
      }
    } else if (notification.notificable_type === 'Import::Operation') {
      const import_operation = notification.notificable as ImportOperation;
      if(import_operation)
      {
        this.router.navigate([`/import/${import_operation.id}/landing`]);
      }
    } else if (notification.notificable_type === 'PublicItem') {
      const public_item = notification.notificable as PublicItem;
      if(public_item) {
        this.router.navigate([public_item.publicable_frontend_link]);
      }
    }
  }

  deleteRecord(notification: Notification) {
    this.service.delete(notification.id).subscribe(
      (response) => {
        this.records = this.records.filter(h => h !== notification);
      }
    );
  }

  downloadExportOperation(notification: Notification) {
    this.exportOperationService.get(notification.notificable.id).subscribe(
      (response) => {
        const record = new ExportOperation().fromJson(response);
        if (record['data']) {
          Downloader.downloadFile(record['data'], record['data_filename'], this.renderer, this.element);
        } else {
          this.snackBar.open(this.translate.instant('exportOperations.emptyResult'), 'x', {
            duration: 4000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'style-error'
          });
        }
      }
    );
  }

  reloadRecord(id) {
    this.loading = true;
    this.service.get(id).subscribe(
      (r) => {
        const record = new Notification().fromJson(r);
        this.records.filter(e => e.id === record.id).forEach(e => {
          Object.assign(e, record);
        });
        this.loading = false;
      }
    );
  }

}
