<mat-progress-bar mode="buffer" class="modules-loader" *ngIf="modulesLoading"></mat-progress-bar>
<div class="new-version-notification" *ngIf="newVersion">
  Eine neuere Version von Insurgo ist verfügbar.
  <a (click)="loadNewVersion()">Aktualisieren</a>
</div>

<mat-sidenav-container class="sidenav-container" *ngIf="!publicRoute">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false" [ngClass]="{'sidenav-iconified': sidenavIconified}"
      [disableClose]="(isHandset$ | async) ? 'false' : 'true'">
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="iconifySidenav()" *ngIf="(isHandset$ | async) === false" style="margin-left: -3px">
        <mat-icon>menu</mat-icon>
      </button>
      <a [routerLink]="['/dashboard']" class="sidenav-root-link">
        <img class="img-fluid" src="assets/logow.png">
      </a>
      <span style="flex: 1 1 0%; box-sizing: border-box;"></span>
    </mat-toolbar>
    <mat-nav-list [ngStyle]="sidenavIconified && {'padding-top': '0'}">
      <div class="sidenav-account mat-typography" *ngIf="account">
        <img [src]="account.logo_thumb | safe: 'url'"
            alt="account image" class="sidenav-account-image" *ngIf="account.logo_thumb">
        <img src="assets/android-chrome-144x144.png"
          alt="account image" class="sidenav-account-image" *ngIf="!account.logo_thumb">

        <h4 class="sidenav-account-name">
          {{ (account.title && account.title.length > 25 )? (account.title | slice:0:25) + '......' : (account.title || '-') }}
        </h4>
        <!-- *ngIf="account.state === 'trial' && account.expired_at" -->
        <h5 class="sidenav-account-info" *ngIf="false" style='color: #808080; font-style: italic; font-size: 8px;'>Lizenz: {{ account.expiredAt.format('DD.MM.YYYY HH:mm') }}</h5>
        <h5 class="sidenav-account-info">{{ currentUser.email }}</h5>
      </div>
      <ng-container *ngIf="currentUser?.role !== 'insurgo' && currentUser?.role !== 'office'">
        <a mat-list-item [routerLink]="['/dashboard']" [routerLinkActive]="['is-active-nav-link']">
          <mat-icon class='sidenav-navi-icon'>home</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.dashboard' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/support']" [routerLinkActive]="['is-active-nav-link']" *ngIf="canReadSupport()" class="sidenav-with-badge">
          <mat-icon class='sidenav-navi-icon'>question_answer</mat-icon>
          <div class="sidenav-navi-link"
               [matBadge]="chatNotifications"
               matBadgeColor="warn"
               matBadgeSize="small"
               [matBadgeHidden]="!chatNotifications">
             {{ 'navigation.support' | translate }}
           </div>
        </a>
        <a mat-list-item [routerLink]="['/kunden']" [routerLinkActive]="['is-active-nav-link']" *ngIf="!currentUser?.isClient" [routerLinkActiveOptions]="{exact: true}" (click)="setEntryBackPoint('/kunden')">
          <mat-icon class='sidenav-navi-icon'>account_box</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.clients' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/kunden/privat']" [routerLinkActive]="['is-active-nav-link']" *ngIf="!currentUser?.isClient" (click)="setEntryBackPoint('/kunden/privat')">
          <mat-icon class='sidenav-navi-icon'>person</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.individualClients' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/kunden/firmen']" [routerLinkActive]="['is-active-nav-link']" *ngIf="!currentUser?.isClient" (click)="setEntryBackPoint('/kunden/firmen')">
          <mat-icon class='sidenav-navi-icon'>business</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.companyClients' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/vertraege']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showContractsNavigation()" (click)="setEntryBackPoint('/vertraege')">
          <mat-icon class='sidenav-navi-icon'>edit</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.contracts' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/risiken']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showRisksNavigation()" (click)="setEntryBackPoint('/risiken')">
          <mat-icon class='sidenav-navi-icon'>emoji_transportation</mat-icon>
          <div class="sidenav-navi-link">{{ 'risks.header' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/aufgaben']" [routerLinkActive]="['is-active-nav-link']" *ngIf="!currentUser?.isClient" (click)="setEntryBackPoint('/aufgaben')">
          <mat-icon class='sidenav-navi-icon'>check_box</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.tasks' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/boards']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showBoardsNavigation()" (click)="setEntryBackPoint('/boards')">
          <mat-icon class='sidenav-navi-icon'>view_kanban</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.boards' | translate }}</div>
          <img [src]="'assets/insurgo-plus.png'" alt="PLUS" class="plus-badge" style="margin-left: 90px; margin-top: 5px;" *ngIf="!currentUser?.with_boards && !sidenavIconified"/>
        </a>
        <a mat-list-item [routerLink]="['/inbox']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showInboxNavigation()" (click)="deleteEntryBackPoint()">
          <mat-icon class='sidenav-navi-icon'>inbox</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.inbox' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/postfach']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showPlusInboxNavigation()" (click)="deleteEntryBackPoint()">
          <mat-icon class='sidenav-navi-icon'>move_to_inbox</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.inboxPlus' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/schaden']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showDamagesNavigation()" (click)="setEntryBackPoint('/schaden')">
          <mat-icon class='sidenav-navi-icon'>feedback</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.damages' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/provisionen/erwartungen']" [routerLinkActive]="['is-active-nav-link']" *ngIf="!currentUser?.isClient && currentUser?.with_commission && (currentUser?.isManager || currentUser?.can_commissions)" (click)="deleteEntryBackPoint()">
          <mat-icon class='sidenav-navi-icon'>euro_symbol</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.commissions' | translate }}</div>
        </a>
        <ng-container *ngIf="!currentUser?.isClient && currentUser?.with_debits && (currentUser?.isManager || currentUser?.can_debits)">
          <ng-container *ngIf="sidenavIconified">
            <a mat-list-item [matMenuTriggerFor]="inkassoMenu">
              <mat-icon class='sidenav-navi-icon'>eject</mat-icon>
              <div class="sidenav-navi-link">{{ 'navigation.collections' | translate }}</div>
            </a>

            <mat-menu #inkassoMenu="matMenu">
              <ng-container *ngTemplateOutlet="inkassoMenuLinks"></ng-container>
            </mat-menu>
          </ng-container>

          <ng-container *ngIf="!sidenavIconified">
            <a mat-list-item (click)="toggleShowInkassoSubmenu()">
              <mat-icon class='sidenav-navi-icon'>eject</mat-icon>
              <div class="sidenav-navi-link">{{ 'navigation.collections' | translate }}</div>
              <mat-icon class="menu-button" [ngClass]="{'rotated' : showInkassoSubmenu}">expand_more</mat-icon>
            </a>
            <div #inkassoMenu class="submenu" [ngClass]="{'expanded' : showInkassoSubmenu }">
              <ng-container *ngTemplateOutlet="inkassoMenuLinks"></ng-container>
            </div>
          </ng-container>
        </ng-container>
        <a mat-list-item [routerLink]="['/gesellschaften']" [routerLinkActive]="['is-active-nav-link']" *ngIf="!currentUser?.isClient" (click)="deleteEntryBackPoint()">
          <mat-icon class='sidenav-navi-icon'>account_balance</mat-icon>
          <div class="sidenav-navi-link">{{ 'insuranceCompanyItems.header' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/dokumente']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showDocumentsNavigation()" (click)="deleteEntryBackPoint()">
          <mat-icon class='sidenav-navi-icon'>attachment</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.documents' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/administration/kundenportal']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showKundenportalNavigation()" (click)="deleteEntryBackPoint()">
          <mat-icon class='sidenav-navi-icon'>settings</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.administration' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/stream']" [routerLinkActive]="['is-active-nav-link']" *ngIf="showStreamNavigation()" (click)="deleteEntryBackPoint()">
          <mat-icon class='sidenav-navi-icon'>history</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.versions' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/workflows']" [routerLinkActive]="['is-active-nav-link']" (click)="deleteEntryBackPoint()" *ngIf="currentUser?.isManager || currentUser?.isEmployee">
          <span class="material-symbols-sharp sidenav-navi-icon">
            conversion_path
          </span>
          <div class="sidenav-navi-link">{{ 'workflows.header' | translate }}</div>
          <img [src]="'assets/insurgo-plus.png'" alt="PLUS" class="plus-badge" style="margin-left: 65px; margin-top: 5px;" *ngIf="!currentUser?.with_workflows && !sidenavIconified"/>
        </a>
        <a mat-list-item [routerLink]="['/administration']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser?.role === 'manager'" (click)="deleteEntryBackPoint()">
          <mat-icon class='sidenav-navi-icon'>settings</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.administration' | translate }}</div>
        </a>
      </ng-container>
      <ng-container *ngIf="currentUser?.role === 'insurgo' && currentUser?.id">
        <a mat-list-item [routerLink]="['/backend/dashboard']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions">
          <mat-icon class='sidenav-navi-icon'>home</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.dashboard' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/admins']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions">
          <mat-icon class='sidenav-navi-icon'>supervisor_account</mat-icon>
          <div class="sidenav-navi-link">{{ 'administration.navigation.backendUsers' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/all-users']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions">
          <mat-icon class='sidenav-navi-icon'>person</mat-icon>
          <div class="sidenav-navi-link">{{ 'administration.navigation.users' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/backoffice']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions">
          <mat-icon class='sidenav-navi-icon'>admin_panel_settings</mat-icon>
          <div class="sidenav-navi-link">{{ 'administration.navigation.backendOfficeUsers' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/dokumentenkategorien']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('document_categories') !== -1)">
          <mat-icon class='sidenav-navi-icon'>note_add</mat-icon>
          <div class="sidenav-navi-link">{{ 'documentCategories.header' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/versicherungsgruppen']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('insurance_groups') !== -1)">
          <mat-icon class='sidenav-navi-icon'>group_work</mat-icon>
          <div class="sidenav-navi-link">{{ 'administration.navigation.insuranceGroups' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/versicherungskategorien']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('insurance_categories') !== -1)">
          <mat-icon class='sidenav-navi-icon'>category</mat-icon>
          <div class="sidenav-navi-link">{{ 'administration.navigation.insuranceCategories' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/gesellschaften']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('insurance_companies') !== -1)">
          <mat-icon class='sidenav-navi-icon'>business</mat-icon>
          <div class="sidenav-navi-link">{{ 'insuranceCompanies.header' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/oauth']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions">
          <mat-icon class='sidenav-navi-icon'>admin_panel_settings</mat-icon>
          <div class="sidenav-navi-link">{{ 'administration.navigation.oauth' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/vertragsfelder']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('dynamic_fields') !== -1)">
          <mat-icon class='sidenav-navi-icon'>view_stream</mat-icon>
          <div class="sidenav-navi-link">{{ 'dynamicFields.headercontracts' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/kundenfelder']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('dynamic_fields') !== -1)">
          <mat-icon class='sidenav-navi-icon'>view_stream</mat-icon>
          <div class="sidenav-navi-link">{{ 'dynamicFields.headerclients' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/risikoformulare']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('risks') !== -1)">
          <mat-icon class='sidenav-navi-icon'>emoji_transportation</mat-icon>
          <div class="sidenav-navi-link">{{ 'riskFormulars.headerNavi' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/schadenformulare']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('damages') !== -1)">
          <mat-icon class='sidenav-navi-icon'>feedback</mat-icon>
          <div class="sidenav-navi-link">{{ 'damageFormulars.header' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/import_operations']" [routerLinkActive]="['is-active-nav-link']">
          <mat-icon class='sidenav-navi-icon'>move_to_inbox</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.inboxPlus' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backend/workflow-statistics']" [routerLinkActive]="['is-active-nav-link']" *ngIf="currentUser.with_all_permissions || (currentUser.permissions && currentUser.permissions.indexOf('workflow_statistics') !== -1)">
          <mat-icon class='material-symbols-sharp sidenav-navi-icon'>conversion_path</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.workflowStatistics' | translate }}</div>
        </a>
      </ng-container>
      <ng-container *ngIf="currentUser?.role === 'office' && currentUser?.id">
        <a mat-list-item [routerLink]="['/backoffice/kunden']" [routerLinkActive]="['is-active-nav-link']" [routerLinkActiveOptions]="{exact: true}">
          <mat-icon class='sidenav-navi-icon'>account_box</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.clients' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backoffice/kunden/privat']" [routerLinkActive]="['is-active-nav-link']">
          <mat-icon class='sidenav-navi-icon'>person</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.individualClients' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backoffice/kunden/firmen']" [routerLinkActive]="['is-active-nav-link']">
          <mat-icon class='sidenav-navi-icon'>business</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.companyClients' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backoffice/vertraege']" [routerLinkActive]="['is-active-nav-link']">
          <mat-icon class='sidenav-navi-icon'>edit</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.contracts' | translate }}</div>
        </a>
        <a mat-list-item [routerLink]="['/backoffice/aufgaben']" [routerLinkActive]="['is-active-nav-link']">
          <mat-icon class='sidenav-navi-icon'>check_box</mat-icon>
          <div class="sidenav-navi-link">{{ 'navigation.tasks' | translate }}</div>
        </a>
      </ng-container>
      <ng-container *ngIf="currentUser?.id && sidenavIconified && !currentUser?.isClient && !currentUser?.isOffice && currentUser?.role !== 'insurgo'">
        <a mat-list-item [matMenuTriggerFor]="supportMenu">
          <mat-icon class='sidenav-navi-icon'>contact_support</mat-icon>
          <div class="sidenav-navi-link">{{ 'footer.support' | translate }}</div>
        </a>

        <mat-menu #supportMenu="matMenu">
          <ng-container *ngTemplateOutlet="supportMenuLinks"></ng-container>
        </mat-menu>
      </ng-container>

      <ng-container *ngIf="currentUser?.id && !sidenavIconified && !currentUser?.isClient && !currentUser?.isOffice && currentUser?.role !== 'insurgo'">
        <a mat-list-item (click)="toggleShowSupportSubmenu()">
          <mat-icon class='sidenav-navi-icon'>contact_support</mat-icon>
          <div class="sidenav-navi-link">{{ 'footer.support' | translate }}</div>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showSupportSubmenu}">expand_more</mat-icon>
        </a>
        <div #supportSubmenu class="submenu" [ngClass]="{'expanded' : showSupportSubmenu }">
          <ng-container *ngTemplateOutlet="supportMenuLinks"></ng-container>
        </div>
      </ng-container>

      <ng-template #supportMenuLinks>
        <div style="margin-bottom: 22px;">
          <a mat-menu-item href="http://help.insurgo.cloud/" target="_blank">
            <mat-icon class="submenu-icon">chrome_reader_mode</mat-icon>{{ 'footer.helpSites' | translate }}
          </a>
          <a mat-menu-item href="https://www.insurgo.de/training/" target="_blank">
            <mat-icon class="submenu-icon">school</mat-icon>{{ 'footer.schoolLink' | translate }}
          </a>
          <a mat-menu-item href="https://ideen.insurgo.cloud/" target="_blank">
            <mat-icon class="submenu-icon">insert_emoticon</mat-icon>{{ 'footer.ideasLink' | translate }}
          </a>
          <a mat-menu-item class='trigger-intercom' *ngIf="showIntercomLink()">
            <mat-icon class="submenu-icon">chat_bubble_outline</mat-icon>{{ 'footer.supportLink' | translate }}
          </a>
          <a mat-menu-item href="https://changelog.insurgo.io/" target="_blank">
            <mat-icon class="submenu-icon">loop</mat-icon>{{ 'footer.changelogLink' | translate }}
          </a>
        </div>
      </ng-template>

      <ng-template #inkassoMenuLinks>
        <div>
          <a mat-menu-item [routerLink]="['/inkasso']">
            <mat-icon class="submenu-icon">format_list_bulleted</mat-icon>{{ 'debits.konten' | translate }}
          </a>
          <a mat-menu-item [routerLink]="['/inkasso/rechnungsstellung']">
            <mat-icon class="submenu-icon">receipt_long</mat-icon>{{ 'debits.rechnungsstellung' | translate }}
          </a>
          <a mat-menu-item [routerLink]="['/inkasso/forderungen/offen']">
            <mat-icon class="submenu-icon">approval</mat-icon>{{ 'debits.forderungen' | translate }}
          </a>
        </div>
      </ng-template>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav
    #rightDrawer
    position="end"
    [attr.role]="'dialog'"
    [mode]="'over'"
    [opened]="false"
    class="right-sidenav"
    (openedStart)="rightDrawerState = 'opened'"
    (closedStart)="closedRightDrawer()">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="mat-subtitle-2">
      <mat-tab>
        <ng-template matTabLabel>
          <mat-icon [matTooltip]="'navigation.notifications' | translate">notification_important</mat-icon>
        </ng-template>
        <ng-template matTabContent>
          <app-notifications-index *ngIf="rightDrawerState === 'opened'"></app-notifications-index>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="currentUser && !currentUser?.isClient">
        <ng-template matTabLabel>
          <mat-icon [matTooltip]="'navigation.quickAccess' | translate">bookmark</mat-icon>
        </ng-template>
        <ng-template matTabContent>
          <app-bookmarks-index *ngIf="rightDrawerState === 'opened'" [user]="currentUser"></app-bookmarks-index>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <span style="flex: 1 1 auto;"></span>

      <ng-container *ngIf="currentUser?.isClient && (currentUser.institutions.length > 1 || (currentUser.from_contacts && currentUser.institutions.length > 0))">
        <button mat-button [matMenuTriggerFor]="institutionsMenu" style="height: 60px;">
          {{ selectedInstitution?.title }}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #institutionsMenu="matMenu">
          <button mat-menu-item *ngFor="let institution of currentUser.institutions" (click)="selectInstitution(institution.id)">
            {{ institution.title }}
          </button>
          <button mat-menu-item *ngIf="currentUser.from_contacts" (click)="selectInstitution(0)">
            {{ 'navigation.noInstitutionSelect' | translate }}
          </button>
        </mat-menu>
      </ng-container>

      <button type="button" aria-label="open assist" mat-icon-button (click)="openAssist()" *ngIf="currentUser?.role !== 'insurgo' && currentUser?.role !== 'office' && !currentUser?.isClient" style="margin-right: 10px;">
        <mat-icon style="font-size: 20px;">auto_awesome</mat-icon>
      </button>

      <button type="button"
              aria-label="open search"
              mat-icon-button (click)="openSearch($event)"
              (document:keydown.meta.e)="openSearch($event)"
              (document:keydown.control.e)="openSearch($event)"
              [matTooltip]="'navigation.searchShortcut' | translate"
              [matTooltipShowDelay]="1000"
              *ngIf="currentUser?.role !== 'insurgo' && currentUser?.role !== 'office' && !currentUser?.isClient"
              style="margin-right: 10px;">
        <mat-icon style="font-size: 20px;">search</mat-icon>
      </button>

      <button type="button" aria-label="format list" mat-icon-button (click)="rightDrawer.open()" *ngIf="currentUser?.role !== 'insurgo' && currentUser?.role !== 'office' && !currentUser?.isClient">
        <mat-icon aria-label="format list icon" [matBadge]="notifications" matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="!notifications">view_module</mat-icon>
      </button>

      <button mat-button [matMenuTriggerFor]="settingsMenu" style="height: 60px; padding-left: 22px; padding-right: 10px;">
        <ng-container *ngIf="currentUser?.isClient">
          {{ currentUser?.name }}
        </ng-container>
        <ng-container *ngIf="!currentUser?.isClient">
          <img [src]="currentUser.profilePicture | safe: 'url'" alt="profile picture" class="user-profile-picture" [matTooltip]="currentUser.name">
        </ng-container>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #settingsMenu="matMenu">
        <a mat-menu-item [routerLink]="['/einstellungen/profil']">
          <mat-icon>account_circle</mat-icon>
          {{ 'navigation.profile' | translate }}
        </a>
        <button mat-menu-item (click)="toggleThemeDark()">
          <mat-icon *ngIf="!currentUser?.theme_dark">toggle_off</mat-icon>
          <mat-icon color='primary' *ngIf="currentUser?.theme_dark">toggle_on</mat-icon>
          {{ 'users.theme_dark' | translate }}
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logOut()">
          <mat-icon>exit_to_app</mat-icon>
          {{ 'navigation.logOut' | translate }}
        </button>
      </mat-menu>
    </mat-toolbar>

    <mat-sidenav-container>
      <mat-sidenav
        #documentDrawer
        class="document-sidenav"
        opened="false"
        position="end"
        mode="side"
        fixedInViewport="true"
        fixedTopGap="60"
        fixedBottomGap="30">
        <app-document-splitview></app-document-splitview>
      </mat-sidenav>
      <mat-sidenav-content style="height: calc(100vh - 90px)">
        <div class="content mat-typography mat-app-background" [ngClass]="{ 'document-sidenav-expanded': documentDrawer.opened }" #contentPadding>
          <ng-container *ngTemplateOutlet="routerLink"></ng-container>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <footer fxLayout="row" fxLayoutAlign="space-between center" class="mat-typography mat-app-background" fxHide.lt-sm  [ngClass]="{'iconified' : sidenavIconified }">
      <div fxFlex>©{{year}} {{ 'footer.insurgo' | translate }}</div>
      <div fxFlex fxLayoutAlign="end" fxLayout="row" fxLayoutGap="20px" *ngIf="!currentUser?.isClient">
        <a href="https://changelog.insurgo.io/" target="_blank" id="changelog-link">{{ 'footer.changelog' | translate }}</a>
        <a href="https://insurgo.de/agb/" target="_blank">{{ 'footer.agb' | translate }}</a>
        <a href="https://insurgo.de/impressum/" target="_blank">{{ 'footer.imprint' | translate }}</a>
      </div>
    </footer>

  </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="publicRoute">
  <div class="content mat-typography mat-app-background" style="padding: 0px;">
    <ng-container *ngTemplateOutlet="routerLink"></ng-container>
  </div>
</div>

<ng-template #routerLink>
  <router-outlet (deactivate)="onDeactivate()"></router-outlet>
</ng-template>
