import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavigationComponent } from './navigation/navigation.component';
import { SharedModule } from '@app/modules/shared/shared.module';
import { BookmarksModule } from '@app/modules/bookmarks/bookmarks.module';
import { NotificationsModule } from '@app/modules/notifications/notifications.module';
import { ChatGptModule } from '@app/modules/chat-gpt/chat-gpt.module';

import { IntercomModule } from '@supy-io/ngx-intercom';
import { SearchComponent } from './search/search.component';

@NgModule({
  declarations: [
    NavigationComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    BookmarksModule,
    NotificationsModule,
    ChatGptModule,
    IntercomModule.forRoot({
      appId: 'uvw5roe3',
      updateOnRouterChange: true
    })
  ],
  exports: [NavigationComponent]
})
export class NavigationModule { }
