export class SearchedItem {
  id: number;
  entry_title: string;
  frontend_link: string;
  icon: string;
  priority: number;
  data: any;

  fromJson(source: any): SearchedItem {
    Object.assign(this, source);
    return this;
  }
}
