<mat-card appearance="outlined" class="note-card" *ngFor="let record of records" (click)="navigateTo(record)" style="cursor: pointer;">
  <mat-card-header>
    <mat-card-title fxLayout="row" (click)="$event.stopPropagation()">
      <div fxFlex fxLayoutAlign="start">
        <span>{{ record.notificable_type === 'User' ? record.notificable.name : record.notificable.author_name }}</span>
      </div>
      <div class="auto-fill"></div>

      <div fxFlex
        fxLayoutAlign="end"
        *ngIf="(record.notificable_type !== 'Topic' || !record.notificable.start_date) && record.notificable_type !== 'ExportOperation' && record.notificable_type !== 'User' && record.notificable_type !== 'Contract' && record.notificable_type !== 'Damage'">
        {{ record.createdAt.format('DD.MM.YY HH:mm') }}
        <mat-icon class="delete-notification-icon" [matTooltip]="'notifications.destroy' | translate" (click)="deleteRecord(record)">close</mat-icon>
      </div>

      <div fxFlex
        fxLayoutAlign="end"
        *ngIf="(record.notificable_type === 'Topic' && record.notificable.start_date) && record.notificable_type !== 'ExportOperation' && record.notificable_type !== 'User'">
        {{ record.notificable.start_date | date:'dd.MM.yy HH:mm' }}
        <mat-icon class="delete-notification-icon" [matTooltip]="'notifications.destroy' | translate" (click)="deleteRecord(record)">close</mat-icon>
      </div>

      <div fxFlex
        fxLayoutAlign="end"
        *ngIf="record.notificable_type === 'ExportOperation' && record.notificable.status !== 'started'">
        {{ record.createdAt.format('DD.MM.YY HH:mm') }}
        <mat-icon class="delete-notification-icon" [matTooltip]="'notifications.destroy' | translate" (click)="deleteRecord(record)">close</mat-icon>
      </div>

      <div fxFlex
        fxLayoutAlign="end"
        *ngIf="record.notificable_type === 'ExportOperation' && record.notificable.status === 'started'">
        {{ record.createdAt.format('DD.MM.YY HH:mm') }}
        <mat-icon class="delete-notification-icon" [matTooltip]="'actions.reload' | translate" (click)="reloadRecord(record.id)">refresh</mat-icon>
      </div>

      <div fxFlex
        fxLayoutAlign="end"
        *ngIf="record.notificable_type === 'User'">
        {{ record.createdAt.format('DD.MM.YY HH:mm') }}
        <mat-icon class="delete-notification-icon" [matTooltip]="'notifications.destroy' | translate" (click)="deleteRecord(record)">close</mat-icon>
      </div>

      <div fxFlex
        fxLayoutAlign="end"
        *ngIf="record.notificable_type === 'Contract' || record.notificable_type === 'Damage'">
        {{ record.createdAt.format('DD.MM.YY HH:mm') }}
        <mat-icon class="delete-notification-icon" [matTooltip]="'notifications.destroy' | translate" (click)="deleteRecord(record)">close</mat-icon>
      </div>

    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="note-card-content">

    <ng-container *ngIf="record.notificable_type !== 'ExportOperation' && record.notificable_type !== 'User' && record.notificable_type !== 'Contract' && record.notificable_type !== 'Damage' && record.notificable_type !== 'Import::Operation' && record.notificable_type !== 'PublicItem'">
      <span class="notification-label">
        {{ (record.notificable_type === 'Topic' ? 'topics.header' : (record.notificable_type === 'Message' ? 'messages.header' : 'notes.header')) | translate }}
      </span>
      <span class="notification-label" *ngIf="record.notificable_type === 'Topic' && record.reminder">{{ 'topics.dueSoon' | translate }}</span>
      <div *ngIf="record.notificable_type === 'Note' || record.notificable_type === 'Message'"><br/>{{ record.notificable.bodyHtmlLess }}</div>
      <div *ngIf="record.notificable_type === 'Topic'"><br/>{{ record.notificable.title }}</div>
    </ng-container>

    <ng-container *ngIf="record.notificable_type === 'Import::Operation'">
      <div>
        <br/>
        {{ 'import.state_done' | translate }}
        <br/>
      </div>
    </ng-container>

    <ng-container *ngIf="record.notificable_type === 'PublicItem'">
      <span class="notification-label">
        {{ (record.notificable.publicable_type === 'Risk' ? 'risks.header' : 'damages.header') | translate }}
      </span>
      <div>
        <br/>
        {{ 'publicItems.notificationTxt' | translate: { client_name: record.notificable.client_entry_title, header_title: record.notificable.publicable_header_title } }}
      </div>
    </ng-container>

    <ng-container *ngIf="record.notificable_type === 'ExportOperation'">
      <div *ngIf="record.notificable.status !== 'completed'">
        <br/>{{ 'notifications.exportStarted' | translate }}
      </div>
      <div *ngIf="record.notificable.status === 'completed'">
        <br/>
        {{ 'notifications.exportCompleted' | translate }}
        <br/>
        <br/>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <a (click)="downloadExportOperation(record)" href="javascript: void(0);">{{ 'notifications.downloadFile' | translate }}</a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="record.notificable_type === 'User'">
      <span class="notification-label">
        {{ 'support.notificationHeader' | translate }}
      </span>
      <div><br/>{{ 'support.notificationTxt' | translate }}</div>
    </ng-container>

    <ng-container *ngIf="record.notificable_type === 'Contract'">
      <span class="notification-label">
        {{ 'contracts.notificationHeader' | translate }}
      </span>
      <div><br/>{{ record.notificable.author_name ? ('contracts.notificationTxt' | translate: { name: record.notificable.author_name }) : 'contracts.notificationTxtDeletedUser' | translate }}</div>
    </ng-container>

    <ng-container *ngIf="record.notificable_type === 'Damage'">
      <span class="notification-label">
        {{ 'damages.notificationHeader' | translate }}
      </span>
      <div><br/>{{ record.notificable.author_name ? ('damages.notificationTxt' | translate: { name: record.notificable.author_name }) : 'damages.notificationTxtDeletedUser' | translate }}</div>
    </ng-container>
  </mat-card-content>
</mat-card>

<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>

<div class="no-items-container" *ngIf="!loading && records.length === 0">
  {{ 'list_is_empty' | translate }}
</div>
