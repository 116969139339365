import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  {
    path: 'gesellschaften',
    loadChildren: () => import('./modules/insurance-company-items/insurance-company-items.module').then(mod => mod.InsuranceCompanyItemsModule)
  },
  {
    path: 'inkasso',
    loadChildren: () => import('./modules/inkasso/inkasso.module').then(mod => mod.InkassoModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./modules/inbox/inbox.module').then(mod => mod.InboxModule)
  },
  {
    path: 'postfach',
    loadChildren: () => import('./modules/plus-inbox/plus-inbox.module').then(mod => mod.PlusInboxModule)
  },
  {
    path: 'dokumente',
    loadChildren: () => import('./modules/documents/documents.module').then(mod => mod.DocumentsModule)
  },
  {
    path: 'kunden',
    loadChildren: () => import('./modules/clients/clients.module').then(mod => mod.ClientsModule)
  },
  {
    path: 'kunden/:clientId/kontakte',
    loadChildren: () => import('./modules/contacts/contacts.module').then(mod => mod.ContactsModule)
  },
  {
    path: 'aufgaben',
    loadChildren: () => import('./modules/topics/topics.module').then(mod => mod.TopicsModule)
  },
  {
    path: 'boards',
    loadChildren: () => import('./modules/boards/boards.module').then(mod => mod.BoardsModule)
  },
  {
    path: 'notizen',
    loadChildren: () => import('./modules/notes/notes.module').then(mod => mod.NotesModule)
  },
  {
    path: 'vertraege',
    loadChildren: () => import('./modules/contracts/contracts.module').then(mod => mod.ContractsModule)
  },
  {
    path: 'risiken',
    loadChildren: () => import('./modules/risks/risks.module').then(mod => mod.RisksModule)
  },
  {
    path: 'schaden',
    loadChildren: () => import('./modules/damages/damages.module').then(mod => mod.DamagesModule)
  },
  {
    path: 'checkliste',
    loadChildren: () => import('./modules/damage-checks/damage-checks.module').then(mod => mod.DamageChecksModule)
  },
  {
    path: 'kostenrechnung',
    loadChildren: () => import('./modules/damage-costs/damage-costs.module').then(mod => mod.DamageCostsModule)
  },
  {
    path: 'auth',
    data: { publicRoute: true },
    loadChildren: () => import('./modules/authorization/authorization.module').then(mod => mod.AuthorizationModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('./modules/administration/administration.module').then(mod => mod.AdministrationModule)
  },
  {
    path: 'backend',
    loadChildren: () => import('./modules/backend/backend.module').then(mod => mod.BackendModule)
  },
  {
    path: 'stream',
    loadChildren: () => import('./modules/versions/versions.module').then(mod => mod.VersionsModule)
  },
  {
    path: '404',
    data: { publicRoute: true },
    loadChildren: () => import('./modules/not-found/not-found.module').then(mod => mod.NotFoundModule)
  },
  {
    path: 'einstellungen',
    loadChildren: () => import('./modules/settings/settings.module').then(mod => mod.SettingsModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./modules/support/support.module').then(mod => mod.SupportModule)
  },
  {
    path: 'provisionen',
    loadChildren: () => import('./modules/commissions/commissions.module').then(mod => mod.CommissionsModule)
  },
  {
    path: 'backoffice',
    loadChildren: () => import('./modules/office/office.module').then(mod => mod.OfficeModule)
  },
  {
    path: 'workflows',
    loadChildren: () => import('./modules/workflows/workflows.module').then(mod => mod.WorkflowsModule)
  },
  {
    path: 'import',
    loadChildren: () => import('./modules/import/import.module').then(mod => mod.ImportModule)
  },
  {
    path: 'share',
    loadChildren: () => import('./modules/share/share.module').then(mod => mod.ShareModule)
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
