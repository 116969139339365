import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { SharedModule } from '@app/modules/shared/shared.module';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';

@NgModule({
    declarations: [ConfirmComponent, CalendarHeaderComponent],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        ConfirmComponent
    ]
})
export class HelpersModule { }
