<div class="mat-dialog-sticky-wrapper">
  <mat-dialog-content class="mat-subtitle-2" style="padding: 0px !important;" fxLayout="column">
    <mat-form-field fxFlex class="globe-search-field">
      <mat-icon matIconPrefix style="color: #808080;">search</mat-icon>
      <input matInput [formControl]="queryCtrl" #search autocomplete='new-password' [matAutocomplete]="auto"
    [matAutocompleteConnectedTo]="origin" [placeholder]="'globeSearch.placeholder' | translate">
      <mat-icon matIconSuffix style="color: #808080; cursor: pointer;" (click)="closeDialog()" *ngIf="!loading">close</mat-icon>
      <mat-spinner matIconSuffix mode="indeterminate" diameter="18" *ngIf="loading"></mat-spinner>
    </mat-form-field>
    <ng-container *ngIf="!queryCtrl.value && lastRecords.length > 0">
      <mat-option *ngFor="let item of lastRecords" [value]="item" [disabled]="!item.id" class="search-history-item" (click)="openLastRecord(item)">
        <mat-icon style="font-size: 12px; height: 12px; width: 14px;">schedule</mat-icon>
        <span><mat-icon style="font-size: 12px; height: 12px; width: 14px;">{{ item.icon }}</mat-icon>{{ item.entry_title }}</span>
      </mat-option>
    </ng-container>
    <div style="height: 1px; margin-bottom: 5px;" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    </div>
    <mat-autocomplete autoActiveFirstOption (optionSelected)="selectSearchedItem($event)" #auto="matAutocomplete" class="global-search-autocomplete">
      <mat-option *ngFor="let item of records" [value]="item" [disabled]="!item.id" class="select-option-nowrap" style="height: 60px;">
        <ng-container *ngIf="item.id">
          <mat-icon style="font-size: 12px; height: 12px; width: 14px;">{{ item.icon }}</mat-icon>
          <span [innerHTML]="item.entry_title | bwrap: queryCtrl.value | safe: 'html'"></span>
          <br/>
          <small style="color: #808080;">
            <ng-container *ngIf="item.data?.dob">
              {{ item.data.dob | date:'dd.MM.yyyy' }} /
            </ng-container>
            <ng-container *ngIf="item.data?.zip || item.data?.city">
              <span [innerHTML]="[item.data.zip, item.data.city].join(' ') | bwrap: queryCtrl.value | safe: 'html'"></span>
            </ng-container>
            <ng-container *ngIf="item.data?.client_name">
              <mat-icon style="font-size: 12px; height: 15px; width: 14px; margin-right: 0px;">{{ item.data?.client_icon }}</mat-icon>
              {{ item.data.client_name }} |
            </ng-container>
            <ng-container *ngIf="item.data?.contract_number">
              {{ 'contracts.contract_number' | translate }}: <span [innerHTML]="item.data.contract_number | bwrap: queryCtrl.value | safe: 'html'"></span>
            </ng-container>
            <ng-container *ngIf="item.data?.contract_entry_title">
              <mat-icon style="font-size: 12px; height: 15px; width: 14px; margin-right: 0px;">edit</mat-icon>
              {{ item.data.contract_entry_title }} /
            </ng-container>
            <ng-container *ngIf="item.data?.insurance_category_name">
              {{ item.data.insurance_category_name }} /
            </ng-container>
            <ng-container *ngIf="item.data?.loss_date">
              {{ item.data.loss_date ? (item.data.loss_date | date:'dd.MM.yyyy') : '-' }}
            </ng-container>
            <ng-container *ngIf="item.data?.client_reverse_entry_title">
              {{ 'risks.client' | translate }}: {{ item.data.client_reverse_entry_title }}
            </ng-container>
          </small>
        </ng-container>
        <ng-container *ngIf="!item.id">
          <span>{{ 'noAutocompleteFound' | translate }}</span>
        </ng-container>
      </mat-option>
    </mat-autocomplete>
  </mat-dialog-content>
</div>
