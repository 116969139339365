import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map,  switchMap, debounceTime, startWith, takeUntil, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, Subject, of, concat } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import sortBy from 'lodash-es/sortBy';
import { Router } from '@angular/router';

import { ClientService } from '@app/services/client.service';
import { ContractService } from '@app/services/contract.service';
import { DamageService } from '@app/services/damage.service';
import { RiskService } from '@app/services/risk.service';
import { SearchedItemService } from '@app/services/searched-item.service';
import { SearchedItem } from '@app/models/searched-item.model';
import { User } from '@app/models/user.model';
import { Analytics } from '@app/helpers/analytics';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  currentUser: User;
  protected destroy$ = new Subject<void>();
  queryCtrl: UntypedFormControl = new UntypedFormControl();
  query: string;

  records: SearchedItem[] = [];
  loading = false;

  lastRecords: SearchedItem[] = [];

  constructor(public translate: TranslateService,
              public clientService: ClientService,
              public contractService: ContractService,
              public damageService: DamageService,
              public riskService: RiskService,
              private authService: AuthService,
              public service: SearchedItemService,
              private router: Router,
              public dialogRef: MatDialogRef<SearchComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.currentUser = new User().fromJson(JSON.parse(this.authService.currentUser));
    this.service.index().pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.lastRecords = response.body.map(u => new SearchedItem().fromJson(u));
      }
    );
    this.queryCtrl
        .valueChanges
        .pipe(
          takeUntil(this.destroy$),
          startWith(''),
          debounceTime(600),
          // clients
          switchMap(value => {
            this.records = [];
            this.loading = true;
            value = value && (typeof value === 'string' || value instanceof String) && value.trim().length > 1 ? value : '';
            this.query = value;
            const params = { query: this.query, state: 'all', serializer: 'light', per_page: 100 };
            return this.query !== '' ? this.clientService.index(1, params) : of({ body: [] });
          }),
          tap((response: any) => {
            this.records = response.body.map(x => {
              const item = new SearchedItem().fromJson(x);
              item.data = {
                dob: x['dob'],
                zip: x['zip'],
                city: x['city']
              };
              return item;
            });
          }),
          // contracts
          switchMap(_ => {
            const params = { otherquery: this.query, stored_state: 'active', serializer: 'light', per_page: 100 };
            return this.query !== '' ? this.contractService.index(1, params) : of({ body: [] });
          }),
          tap((response: any) => {
            response.body.map(x => {
              const item = new SearchedItem().fromJson(x);
              item.data = {
                contract_number: x['contract_number'],
                client_name: x['client_name'],
                client_icon: x['client_icon']
              };
              return item;
            }).forEach(y => this.records.push(y));
          }),
          // damages
          switchMap(_ => {
            const params = { otherquery: this.query, state: ['created', 'opened', 'deferred'], per_page: 100 };
            return this.query !== '' ? this.damageService.index(1, params) : of({ body: [] });
          }),
          tap((response: any) => {
            response.body.map(x => {
              const item = new SearchedItem().fromJson(x);
              item.icon = 'feedback';
              item.entry_title = [x['art'], x['vr_number']].join(' | ');
              item.data = {
                internal_key: x['internal_key'],
                insurance_category_name: x['insurance_category_name'],
                loss_date: x['loss_date'],
                contract_entry_title: x['contract_entry_title']
              };
              return item;
            }).forEach(y => this.records.push(y));
          }),
          // risks
          switchMap(_ => {
            const params = { otherquery: this.query, serializer: 'light' };
            return this.query !== '' ? this.riskService.index(1, params) : of({ body: [] });
          }),
          tap((response: any) => {
            response.body.map(x => {
              const item = new SearchedItem().fromJson(x);
              item.icon = 'emoji_transportation';
              item.data = {
                internal_key: x['client_reverse_entry_title']
              };
              return item;
            }).forEach(y => this.records.push(y));
          })
        ).subscribe(
          (response: any) => {
            this.loading = false;
            if (this.queryCtrl.value && this.records.length === 0) {
              this.records.push(new SearchedItem());
            } else {
              const regEx = new RegExp(this.query, "ig");
              this.records.forEach(record => {
                if (record.entry_title.search(regEx) != -1 ||
                    ((record.data?.zip || record.data?.city) && [record.data.zip, record.data.city].join(' ').search(regEx) != -1) ||
                    (record.data?.contract_number && record.data?.contract_number.search(regEx) != -1)) {
                  record.priority = 1;
                } else {
                  record.priority = 2;
                }
              });
              this.records = sortBy(this.records, ['priority', 'entry_title']);
            }
          }
        );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  selectSearchedItem(event: MatAutocompleteSelectedEvent) {
    if (event.option.value && event.option.value.id) {
      const record = new SearchedItem();
      record.entry_title = event.option.value.entry_title;
      record.frontend_link = event.option.value.frontend_link;
      record.icon = event.option.value.icon;
      this.service.save(record).subscribe(
        (response) => {
          Analytics.track(this.currentUser, 'Suche', 'Ergebnis ausgewählt');
          this.router.navigate([event.option.value.frontend_link]);
        }
      );
    }
    this.closeDialog();
  }

  openLastRecord(item: SearchedItem) {
    const record = new SearchedItem();
    record.entry_title = item.entry_title;
    record.frontend_link = item.frontend_link;
    record.icon = item.icon;
    this.service.save(record).subscribe(
      (response) => {
        Analytics.track(this.currentUser, 'Suche', 'Ergebnis ausgewählt');
        this.router.navigate([item.frontend_link]);
      }
    );
    this.closeDialog();
  }
}
